import axios from '../axios.instance'

export default class HotelService {
    async getHotels(){
        try {
            const res = await axios.get('/hotel');
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async getHotelsLazy(params){
        try {
            const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
            // console.log(queryParams)
            const res = await axios.get('/hotel?'+queryParams);
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    //USE TO GET Hotel Name IN CONTRACT UPDATE FORM
    async getHotelName(id){
        try {
            const res = await axios.get('/hotel/gethotelname', {params: { hotelid: id }});
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async addHotel(data) {
        try {
            if(data != ''){
                const res = await axios.post('/hotel', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateHotel(hotel_id, data){
        try {
            if(data != '') {
                const res = await axios.put('/hotel/'+hotel_id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteHotel(hotel_id){
        try {
            if(hotel_id != 0) {
                const res = await axios.delete('/hotel/'+hotel_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async filterHotel(ratingFilter, provinceFilter) {
        try {
            const res = await axios.post('/hotel/filter-hotel', {ratingFilter, provinceFilter});
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }
    
    async filterHotelByName(data) {
        try {
            if(data != '') {
                const res = await axios.get('/hotel/filter-hotel-byname', {params: {hotelname: data}});
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }
    
    //USE TO SEARCH HOTEL NAME IN CONTRACT RATE COMPONENT
    async searchHotel(data) {
        try {
            if(data != '') {
                const res = await axios.get('/hotel/search/', {params: {hotelname: data}})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //HOTEL CONTRACT
    async getContract(hotel_id) {
        try {
            const res = await axios.get('/hotelcontract/', {params: {hotelid: hotel_id}})
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async addHotelContract(data) {
        try {
            if(data != ''){
                const res = await axios.post('/hotelcontract/add', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateHotelContract(contract_id, data) {
        try {
            if(data != ''){
                const res = await axios.put('/hotelcontract/update/'+contract_id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteHotelContract(contract_id) {
        try {
            if(contract_id != 0){
                const res = await axios.delete('/hotelcontract/delete/'+contract_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //HOTEL CONTRACT PERIOD
    async getContractPeriod(contract_id) {
        try {
            const res = await axios.get('/hotelcontractperiod/', {params: {contractid: contract_id}})
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async addContractPeriod(data) {
        try {
            if(data != ''){
                const res = await axios.post('/hotelcontractperiod/add', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateContractPeriod(id, data) {
        try {
            if(data != ''){
                const res = await axios.put('/hotelcontractperiod/update/'+id, {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteContractPeriod(id) {
        try {
            if(id != 0){
                const res = await axios.delete('/hotelcontractperiod/delete/'+id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //HOTEL ROOM RATE
    async getRoomType (hotelid) {
        try {
            if(hotelid != 0){
                const res = await axios.get('/hotel/getroomtype', {params: {hotel_id: hotelid }})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async getRoomRate (hotel_id, period_id, season_n) {
        try {
            const res = await axios.get('/hotelroomrate', {params: {season: season_n, periodid: period_id, hotelid: hotel_id}})
            return res.data;
            
        } catch (error) {
            return error.response.data
        }
    }

    async addRoomRate (data) {
        try {
            if(data) {
                const res = await axios.post('/hotelroomrate/add', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async addExtraBedRate (extrabed) {
        try {
            if(extrabed) {
                const res = await axios.post('/hotelroomrate/extrabed', {extrabed})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteRoomRate(roomtype_id) {
        try {
            if(roomtype_id != 0){
                const res = await axios.delete('/hotelroomrate/delete/'+roomtype_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    //HOTEL SEASON PERIOD
    async getSeasonPeriod(season, id) {
        try {
            if(id != 0) {
                const res = await axios.get('/hotelseason/getperiod', {params: {season: season, contractperiodid: id } })
                return res.data
            }
        } catch (error) {
            return error.response.data
        }
    }

    async saveSeasonPeriod(data) {
        try {
            if(data) {
                const res = await axios.post('/hotelseason/saveperiod', { data })
                return res.data
            }
        } catch (error) {
            return error.response.data
        }
    }

    async deleteSeasonPeriod(seasonperiod_id) {
        try {
            if(seasonperiod_id != 0){
                const res = await axios.delete('/hotelseason/delete/'+seasonperiod_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }
}